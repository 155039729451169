<template>
  <div class="layout-dashboard">
    <div class="p-grid">
      <div class="p-col-12 p-md-6 p-xl-4">
        <div class="card no-gutter widget-overview-box widget-overview-box-3">
          <span class="overview-icon">
            <i class="pi pi-shopping-cart"></i>
          </span>
          <span class="overview-title">Appels d'offre</span>
          <div class="p-grid overview-detail">
            <div class="p-col-6">
              <div class="overview-number">{{ dropdownValue.name }}</div>
              <div class="overview-subtext"></div>
            </div>
            <div class="p-col-6">
              <div class="overview-number">{{ listData.length }}</div>
              <div class="overview-subtext"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-xl-12">
        <div class="card no-gutter orders">
          <div class="card-header">
            <h4></h4>
            <Menu id="orders-button" :popup="true" :model="items" ref="menu" appendTo="body"></Menu>
          </div>
          <!--
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div id="order-tabs-container" class="p-grid order-tabs"></div>
                        </div>
                    </div>-->
          <div class="card">
            <DataTable
              ref="dt"
              :value="listData"
              :paginator="true"
              :rows="10"
              data-key="id"
              :filters="filters"
              responsiveLayout="scroll"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25]"
              currentPageReportTemplate="{totalRecords} appels d'offres"
            >
              <template #header>
                <div
                  class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center"
                >
                  <h5 class="p-mb-2 p-m-md-0">Gestion des appels d'offres</h5>

                  <span v-bind:style="{ width: '33%' }" class="">
                    <a style="margin-right: 15px" href="#" @click="exportAppelsOffres($event)"
                      ><i class="pi pi-download"></i> Exporter</a
                    >
                    <InputText
                      v-bind:style="{ width: '68%' }"
                      v-model="entrepriseName"
                      placeholder="Rechercher une entreprise"
                    />
                    <Button
                      type="button"
                      @click="searching"
                      icon="pi pi-search"
                      class="p-button-success p-d-none p-d-md-inline-flex"
                    />
                  </span>
                </div>
                <br />
                <div style="float: right">
                  <h6 v-if="errorOnSearch" style="color: red">Aucune entreprise n'a été trouvée.</h6>
                  <h6 v-if="errorOnSearchSize" style="color: red">Le champ de recherche est vide.</h6>
                </div>
                <Dropdown id="state" v-model="dropdownValue" :options="dropdownValues" optionLabel="name"></Dropdown>
                <InputText
                  v-model="referenceKeyword"
                  v-bind:style="{ marginLeft: '20px' }"
                  placeholder="Rechercher par référence"
                />
              </template>
              <Column field="entrepriseName" header="Entreprise" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Entreprise</span>
                  {{ slotProps.data.entrepriseName }}
                </template>
              </Column>
              <Column
                field="projetMarche.intitule"
                header="Projet de marché"
                :sortable="true"
                bodyStyle="text-indent:1em"
              >
                <template #body="slotProps">
                  <span class="p-column-title">Projet de marché</span>
                  {{ slotProps.data.projetMarche.intitule }}
                </template>
              </Column>
              <Column field="projetMarche.reference" header="Référence" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Référence</span>
                  {{ slotProps.data.projetMarche.reference }}
                </template>
              </Column>
              <Column field="intitule" header="Intitulé" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Intitulé</span>
                  {{ slotProps.data.intitule }}
                </template>
              </Column>
              <Column field="objet_ao" header="Objet" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Objet</span>
                  {{ slotProps.data.objet_ao }}
                </template>
              </Column>
              <Column field="datePublication" header="Date de publication" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">{{ getFormattedDate(slotProps.data.datePublication) }}</template>
              </Column>
              <Column field="dateLimite" header="Date limite" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">{{ getFormattedDate(slotProps.data.dateLimite) }}</template>
              </Column>
              <Column field="etat" header="Statut" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title"></span>
                  <span v-if="slotProps.data.etat == 'VALIDE'" :class="'product-badge status-instock'">{{
                    enumMap[slotProps.data.etat]
                  }}</span>
                  <span v-if="slotProps.data.etat == 'SOUMIS'" :class="'product-badge status-lowstock'">{{
                    enumMap[slotProps.data.etat]
                  }}</span>
                  <span v-if="slotProps.data.etat == 'CREE'" :class="'product-badge status-init'">{{
                    enumMap[slotProps.data.etat]
                  }}</span>
                  <span v-if="slotProps.data.etat == 'ATTRIBUE'" :class="'product-badge status-instock'">{{
                    enumMap[slotProps.data.etat]
                  }}</span>
                  <span v-if="slotProps.data.etat == 'RENVOYE'" :class="'product-badge status-outofstock'">{{
                    enumMap[slotProps.data.etat]
                  }}</span>
                </template>
              </Column>
              <Column field="action" header="Actions" :sortable="false" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Actions</span>
                  <Button
                    icon="pi pi-eye"
                    class="p-button-outlined p-button-rounded p-button-submit mrg"
                    @click="openDetails(slotProps.data)"
                    v-tooltip.top="'Voir les détails'"
                  />
                  <Button
                    icon="pi pi-thumbs-up"
                    v-if="slotProps.data.etat == 'SOUMIS'"
                    v-tooltip.top="'Valider'"
                    class="p-button-outlined p-button-rounded p-button-success mrg"
                    @click="valid(slotProps.data)"
                  />
                  <Button
                    icon="pi pi-thumbs-down"
                    v-if="slotProps.data.etat == 'SOUMIS'"
                    v-tooltip.top="'Renvoyer pour correction'"
                    class="p-button-outlined p-button-rounded p-button-warning"
                    @click="revoke(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      v-model:visible="dialogStates.isValidOpened"
      header="Valider l'appel d'offre"
      :style="{ width: '1000px' }"
      :modal="true"
      class="p-fluid"
    >
      <div class="prestataires" v-if="true">
        <div class="prestataires__all">
          <h3>Liste des potentiels prestataires</h3>
          <ul class="prestataires-list">
            <li class="prestataires-item item--titled">
              <input
                type="checkbox"
                :value="false"
                v-model="togglePresValueIds"
                @change="onTogglePrestatairesValuesIds"
              />
              Tout sélectionner
            </li>
            <li>
              <input
                style="width: 100%; margin: 10px 0; padding: 5px 5px"
                v-model="companyNameKeyword"
                type="text"
                placeholder="Recherche"
              />
            </li>
            <li>
              <select v-model="companyRankKeyword">
                <option value="">Choisir un profil</option>
                <option :value="tarif.rang" :key="tarif.id" v-for="tarif in tarifs">{{ tarif.adherant }}</option>
              </select>
            </li>
            <li
              class="prestataires-item"
              v-for="prestataire in selectedAppelOffresPrestataires"
              :key="prestataire.id"
              :class="{ 'prestataires--selected': prestataire.id === selectedPrestataire?.id }"
            >
              <input type="checkbox" :value="prestataire.id" v-model="entity.entreprisesChoisisIds" />
              <span @click="seePrestataireDetails(prestataire.id)">
                {{ prestataire.name }} - Créé le {{ prestataire.companyCreatedAt.split('-').reverse().join('/') }} -
                {{ enumMap[prestataire.rangSouscriptionEnCours] }}</span
              >
            </li>
          </ul>
        </div>
        <div class="prestataires__details" v-if="selectedPrestataire?.id">
          <h3 class="selected-prestataire__title">{{ selectedPrestataire?.name }}</h3>
          <ul class="selected-prestataire__list">
            <li class="selected-prestataire__item">
              Adresse : <strong class="to--right">{{ selectedPrestataire?.address }}</strong>
            </li>
            <li class="selected-prestataire__item">
              Numéro d'identification :
              <strong class="to--right">{{ selectedPrestataire?.identificationNumber }}</strong>
            </li>
            <li class="selected-prestataire__item">
              Registre de commerce : <strong class="to--right">{{ selectedPrestataire?.tradeRegister }}</strong>
            </li>
            <li class="selected-prestataire__item">
              Date de création :
              <strong class="to--right">{{ getFormattedDate(selectedPrestataire?.companyCreatedAt) }}</strong>
            </li>
            <li class="selected-prestataire__item">
              Email : <strong class="to--right">{{ selectedPrestataire?.email }}</strong>
            </li>
            <li class="selected-prestataire__item">
              Téléphone 1 : <strong class="to--right">{{ selectedPrestataire?.mobile }}</strong>
            </li>
            <li class="selected-prestataire__item">
              Téléphone 2 : <strong class="to--right">{{ selectedPrestataire?.pointFocal.phone }}</strong>
            </li>
            <li class="selected-prestataire__item">
              Site web : <strong class="to--right">{{ selectedPrestataire?.www }}</strong>
            </li>
            <li class="selected-prestataire__item">
              Profil : <strong class="to--right">{{ enumMap[selectedPrestataire?.rangSouscriptionEnCours] }}</strong>
            </li>
            <!--<li class='selected-prestataire__item'>Forme juridique: <strong class='to--right'>{{ selectedPrestataire?.formeJuridique?.libelle }}</strong></li>-->
            <li class="selected-prestataire__item">
              Pays : <strong class="to--right">{{ selectedPrestataire?.pays?.name }}</strong>
            </li>
          </ul>
        </div>
      </div>
      <!--<div v-else style="text-align: center">
        <h3>Aucune entreprise choisie.</h3>
      </div>-->
      <template #footer>
        <Button label="Annuler" class="p-button p-component p-button-outlined p-button-danger" @click="hideDialog()" />
        <Button
          label="Valider"
          class="p-button p-component p-button-outlined p-button-success"
          @click="valided(entity)"
        />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="revokeDialog"
      :style="{ width: '600px' }"
      header="Correction de l'appel d'offre"
      :modal="true"
      class="p-fluid"
    >
      <label for="description">Objet(s) de la correction de l'appel d'offre.</label>
      <p></p>
      <Textarea id="price" v-model="motif_renvoie" required="true" rows="10" cols="25" />

      <template #footer>
        <Button label="Annuler" class="p-button p-component p-button-outlined p-button-danger" @click="hideDialog()" />
        <Button
          :disabled="!motif_renvoie || motif_renvoie.length === 0"
          label="Renvoyer"
          class="p-button p-component p-button-outlined p-button-success"
          @click="revoked()"
        />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="validDialog"
      :style="{ width: '600px' }"
      header="Valider l'appel d'offre"
      :modal="true"
      class="p-fluid"
    >
      <h3></h3>
      <h5>Êtes-vous sûr de vouloir valider l'appel d'offre ?</h5>
      <h3></h3>

      <template #footer>
        <Button label="NON" class="p-button p-component p-button-outlined p-button-danger" @click="hideDialog()" />
        <Button label="OUI" class="p-button p-component p-button-outlined p-button-success" @click="valided(entity)" />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="rejectDialog"
      :style="{ width: '600px' }"
      header="Rejeter l'appel d'offre"
      :modal="true"
      class="p-fluid"
    >
      <h3></h3>
      <h5>Êtes-vous sûr de vouloir rejeter définitivement l'appel d'offre ?</h5>
      <h3></h3>
      <label for="description">Objet(s) du rejet</label>
      <p></p>
      <Textarea id="price" v-model="motif_rejet" required="true" rows="10" cols="25" />

      <template #footer>
        <Button label="Annuler" class="p-button p-component p-button-outlined p-button-danger" @click="hideDialog()" />
        <Button label="Rejeter" class="p-button p-component p-button-outlined p-button-success" @click="rejected()" />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="listDialog"
      :style="{ width: '600px' }"
      header="Valider l'appel d'offre"
      :modal="true"
      class="p-fluid"
    >
      <h3></h3>
      <h5>Êtes-vous sûr de vouloir valider l'appel d'offre ?</h5>
      <h3></h3>

      <template #footer>
        <Button label="NON" class="p-button p-component p-button-outlined p-button-danger" @click="hideDialog()" />
        <Button label="OUI" class="p-button p-component p-button-outlined p-button-success" @click="valided()" />
      </template>
    </Dialog>
    <Sidebar class="p-sidebar-md" v-model:visible="visibleLeft" position="right">
      <h4>Détails de l'appel d'offre : {{ detail.intitule }}</h4>
      <br />
      <ul style="list-style: none; padding: 0">
        <li class="detail-list-item">
          État : <b style="float: right">{{ enumMap[detail.etat] }}</b>
        </li>
        <li class="detail-list-item">
          Type de régime :
          <b style="float: right">{{ detail?.projetMarche?.regimes.map((regime) => enumMap[regime]).join(', ') }}</b>
        </li>
        <li class="detail-list-item">
          Référence : <b style="float: right">{{ detail.reference }}</b>
        </li>
        <li class="detail-list-item">
          Projet de marché associé : <b style="float: right">{{ detail.projetMarche.intitule }}</b>
        </li>
        <li class="detail-list-item">
          Entreprise : <b style="float: right">{{ detail?.focalPoint.entreprise?.name }}</b>
        </li>
        <li class="detail-list-item">
          Point focal : <b style="float: right">{{ detail?.focalPoint.name }}</b>
        </li>
        <li class="detail-list-item">
          Email : <b style="float: right">{{ detail?.focalPoint.email }}</b>
        </li>
        <li class="detail-list-item">
          Téléphone 1 : <b style="float: right">{{ detail?.focalPoint.mobile }}</b>
        </li>
        <li class="detail-list-item">
          Téléphone 2 : <b style="float: right">{{ detail?.focalPoint.phone || 'N/A' }}</b>
        </li>
        <li class="detail-list-item">
          Site web : <b style="float: right">{{ detail?.focalPoint.entreprise.website || 'N/A' }}</b>
        </li>
        <li v-if="detail?.motifRenvoi" class="detail-list-item">
          Motif de renvoi : <b style="float: right; color: red">{{ detail?.motifRenvoi }}</b>
        </li>
        <li v-if="!detail?.motifRenvoi" class="detail-list-item">
          Motif de renvoi : <b style="float: right">{{ 'N/A' }}</b>
        </li>
        <li class="detail-list-item">
          Objet : <b style="float: right">{{ detail.objet_ao }}</b>
        </li>
        <li class="detail-list-item">
          Domaine(s) métier(s) :
          <b>
            <div v-for="d in detail.projetMarche.domaineMetiers" :key="d.id">- {{ d.libelle }}</div>
          </b>
        </li>
        <li class="detail-list-item">
          Détails :
          <b style="float: right">
            <p style="font-size: 1rem">{{ detail.details }}</p>
          </b>
        </li>
        <li class="detail-list-item">
          Instructions :
          <b style="float: right">
            <p style="font-size: 1rem">{{ detail.instructions }}</p>
          </b>
        </li>
        <li class="detail-list-item">
          Date publication : <b style="float: right">{{ getFormattedDate(detail.datePublication) }}</b>
        </li>
        <li class="detail-list-item">
          Date limite : <b style="float: right">{{ getFormattedDate(detail.dateLimite) }}</b>
        </li>
        <li class="detail-list-item" v-show="detail.etat === 'ATTRIBUE'">
          Montant HT : <b style="float: right">{{ toCurrency(detail?.contrat?.montantHt) }}</b>
        </li>
      </ul>

      <br />
      <Panel header="Pièces jointes">
        <ul style="list-style: none; padding: 0">
          <li class="detail-list-item" v-for="(attachment, index) in detail?.piecesJointes" :key="index">
            <a style="cursor: pointer" @click="onDownload($event, attachment.urlPath)"
              ><img :src="getIconFromFileName(attachment.name)" width="25" />
              {{ getFileNameFromUrl(attachment.name) }}</a
            >
          </li>
        </ul>
      </Panel>

      <br />
      <Panel header="Attribué à" v-show="detail.etat === 'ATTRIBUE'">
        <DataTable :value="detail.entreprisesAttribuees" responsiveLayout="scroll">
          <Column field="name" header="Nom" :sortable="false" bodyStyle="text-indent:1em">
            <template #body="slotProps">
              <span class="p-column-title">Nom</span>
              {{ slotProps.data.name }}
            </template>
          </Column>

          <Column field="pointFocal" header="Point focal" :sortable="false" bodyStyle="text-indent:1em">
            <template #body="slotProps">
              <span class="p-column-title">Point focal</span>
              {{ slotProps.data.pointFocal.name }}
            </template>
          </Column>

          <Column field="email" header="Email" :sortable="false" bodyStyle="text-indent:1em">
            <template #body="slotProps" :sortable="false">
              <span class="p-column-title">Email</span>
              {{ slotProps.data.pointFocal.email }}
            </template>
          </Column>

          <Column field="mobile" header="Téléphone" :sortable="false" bodyStyle="text-indent:1em">
            <template #body="slotProps" :sortable="false">
              <span class="p-column-title">Téléphone</span>
              {{ slotProps.data.pointFocal.mobile }}
            </template>
          </Column>
        </DataTable>
      </Panel>

      <br />

      <Button
        icon="pi pi-thumbs-down"
        v-if="detail.etat == 'SOUMIS'"
        label="Renvoyer pour correction"
        class="p-button-outlined p-button-rounded p-button-warning p-mr-2"
        @click="revoke(detail)"
      />
      &nbsp;&nbsp;
      <Button
        icon="pi pi-thumbs-up"
        v-if="detail.etat == 'SOUMIS'"
        label="Valider"
        class="p-button-outlined p-button-rounded p-button-success"
        @click="valid(detail)"
      />
    </Sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EnumFormatMixin from '@/mixins/enum-format';
import DateFormatMixin from '@/mixins/date-format';
import FilesMixin from '../mixins/file-view';
import moment from 'moment';
import XLSX from 'xlsx';
import http from '../services/http.service';

export default {
  mixins: [EnumFormatMixin, DateFormatMixin, FilesMixin],
  data() {
    return {
      togglePresValueIds: false,
      dialogStates: {
        isValidOpened: false,
      },
      entity: {
        etat: null,
        motifRejet: null,
        motifRenvoi: null,
        entreprisesChoisisIds: [],
      },
      companyNameKeyword: '',
      companyRankKeyword: '',
      referenceKeyword: '',
      selectedPrestataire: null,
      listppm: [],
      ppm: null,
      user: null,
      config: null,
      token: '',
      id: '',
      id_pm: null,
      entrepriseId: '',
      entrepriseName: '',
      intitule: '',
      objet: '',
      reference: '',
      realisations: '',
      date_fin: '',
      date_debut: '',
      isEditing: false,
      type_marche: null,
      domaines: null,
      type_marches: ['EXCLUSIF', 'MIXTE', 'LIBRE'],
      mode_passation: null,
      mode_passations: ['DRP', 'AO', 'AMI'],
      nature_prestation: null,
      list_nature_prestation: [],
      type_regime: null,
      type_regimes: ['EXCLUSIF', 'MIXTE', 'NON_EXCLUSIF'],
      totalPPM: 0,
      isDO: false,
      revokeDialog: false,
      validDialog: false,
      rejectDialog: false,
      isError: false,
      listPrestataires: [],
      appelOffre: null,
      sended: false,
      motif_rejet: '',
      motif_renvoie: '',
      dropdownValue: { name: 'Transmis', id: 0 },
      dropdownValues: [
        { name: 'Créé(s)', id: 6 },
        { name: 'Renvoyé(s)', id: 3 },
        { name: 'Transmis', id: 0 },
        { name: 'Validé(s)', id: 1 },
        { name: 'Attribué(s)', id: 7 },
        { name: 'Tous', id: 2 },
      ],
      errorOnSearch: false,
      errorOnSearchSize: false,
      visibleLeft: false,
      detail: null,
    };
  },
  watch: {
    companyNameKeyword: function (keyword) {
      this.searchByEnterpriseName({ keyword, rank: this.companyRankKeyword });
    },

    companyRankKeyword: function (rank) {
      this.searchByEnterpriseName({ keyword: this.companyNameKeyword, rank });
    },

    referenceKeyword: function (keyword) {
      this.filterAO(this.dropdownValue.id, keyword);
    },

    dropdownValue: {
      handler: function (dropdownValue) {
        this.filterAO(dropdownValue.id);
      },
    },

    selectedAppelOffresPrestataires: function (prestataires) {
      if (prestataires && prestataires.length > 0) this.selectedPrestataire = prestataires[0];
    },

    selectedAppelOffre: function (ami) {
      this.entity = {
        etat: ami.etat,
        motifRejet: ami.motifRejet,
        motifRenvoi: ami.motifRenvoi,
        entreprisesChoisisIds: ami.entreprisesChoisis.map((o) => o.id),
      };
    },
  },
  computed: {
    ...mapGetters({
      selectedAo: 'appelsOffres/getEntity',
      selectedAppelOffresPrestataires: 'appelsOffres/getPrestatairesByName',
      listData: 'appelsOffres/getAllEntities',
      tarifs: 'tarifs/getAllEntities',
    }),
  },
  mounted() {
    this.token = localStorage.getItem('token');
    this.config = { headers: { Authorization: `Bearer ${this.token}` } };
    this.fetchProfile();
    // this.fetchAllAO();
    this.fetchAll({ queryParams: { limit: 1000, etat: 0 } });
    this.fetchTarifs();
  },
  methods: {
    ...mapActions({
      fetchPrestatairesById: 'appelsOffres/fetchPrestatairesById',
      createOrUpdate: 'appelsOffres/createOrUpdate',
      fetchAll: 'appelsOffres/fetchAll',
      fetchTarifs: 'tarifs/fetchAll',
    }),
    ...mapMutations({
      searchByEnterpriseName: 'appelsOffres/setCompanyNameKeyword',
    }),
    toCurrency(value) {
      if (value) return value.toLocaleString('fr-FR', { style: 'currency', currency: 'XOF' });
      return;
    },
    exportAppelsOffres(event) {
      event.preventDefault();
      const fileName = `appels-offres-${moment().format('DDMMYYYYHHmmss')}.xlsx`;
      const data = this.listData.map((ao) => ({
        ID: ao.id,
        INSTRUCTION: ao.instructions,
        REFERENCE: ao.reference,
        INTITULE: ao.intitule,
        POINT_FOCAL: ao.focalPoint.name,
        POINT_TEL_1: ao.focalPoint.mobile,
        POINT_TEL_2: ao.focalPoint.phone,
        ENTREPRISE: ao.entrepriseName,
        ENTREPRISES_ATTRIBUEES: ao.entreprisesAttribuees.map((e) => e.name).join(' | '),
        ENTREPRISES_CHOISIES: ao.entreprisesChoisis.map((e) => e.name).join(' | '),
        PROJET_MARCHE: ao.projetMarche.intitule,
        OBJET: ao.objet,
        DATE_PUBLICATION: ao.datePublication,
        DATE_LIMITE: ao.dateLimite,
        DOMAINES_METIERS: ao.domaineMetiers.map((dm) => dm.libelle).join(' | '),
        STATUT: ao.etat,
        MOTIF_REJET: ao.motifRejet,
        MOTIF_RENVOI: ao.motifRenvoi,
        DETAILS: ao.details,
      }));
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'appels_offres');
      XLSX.writeFile(wb, fileName);
    },
    openDetails(dkr) {
      this.detail = dkr;
      this.visibleLeft = true;
    },

    onTogglePrestatairesValuesIds() {
      this.togglePresValueIds = !!this.togglePresValueIds;
      if (this.togglePresValueIds === true)
        this.entity.entreprisesChoisisIds = this.selectedAppelOffresPrestataires.map((prestataire) => prestataire.id);
      else this.entity.entreprisesChoisisIds = [];
    },

    async searching() {
      this.errorOnSearch = false;
      this.errorOnSearchSize = false;

      if (this.entrepriseName.length >= 1) {
        if (this.dropdownValue.id === 2) {
          this.fetchAll({ queryParams: { limit: 1000, entrepriseName: this.entrepriseName, etat: [0, 1, 3, 6, 7] } });
        } else {
          this.fetchAll({ queryParams: { entrepriseName: this.entrepriseName, etat: this.dropdownValue.id } });
        }
      } else {
        this.errorOnSearchSize = true;
        setTimeout(() => (this.errorOnSearchSize = false), 3000);
        this.entrepriseName = '';
        this.dropdownValue = { name: 'Tous', id: 0 };
        this.fetchCompany(0);
      }
    },

    seePrestataireDetails(id) {
      this.selectedPrestataire = this.selectedAppelOffresPrestataires.find((prestataire) => prestataire.id === id);
    },

    async fetchProfile() {
      await http
        .get('/users/getProfile', this.config)
        .then((res) => {
          this.id = res.data.id;
        })
        .catch((e) => {
          console.log('ERROR IN FETCH DATA =>' + e);
        });
    },

    async fetchAllAO() {
      const token = localStorage.getItem('token');
      const URL = '/appel-offres';
      this.config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      await http({
        method: 'get',
        url: URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const data = res.data.data;
          this.listData = data.filter((ao) => ao.etat.includes('SOUMIS') || ao.etat.includes('VALIDE'));
          if (this.listData.length === 0) {
            this.isEmpty = true;
          }
          //this.listpm = this.listData.map(x => x.projetMarche);
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log('ERROR IN FETCH ALL AO =>' + err);
        });
    },

    async filterAO(etat, reference = '') {
      const queryParams = etat == 2 ? { limit: 1000, etat: [0, 1, 3, 6, 7] } : { etat };
      if (reference !== '') queryParams['reference'] = reference;
      if (etat === 2) {
        this.fetchAll({ queryParams });
      } else {
        this.fetchAll({ queryParams });
      }
    },

    async fetchPrestataires(id) {
      await http.get('/appel-offres/' + id + '/findPrestataires', this.config).then((res) => {
        this.listPrestataires = res.data;
      });
    },

    async valided(entity) {
      const data = {
        id: this.appelOffre.id,
        reference: this.appelOffre.reference,
        intitule: this.appelOffre.intitule,
        objet_ao: this.appelOffre.objet_ao,
        details: this.appelOffre.details,
        motifRejet: this.appelOffre.motifRejet,
        datePublication: this.appelOffre.datePublication,
        dateLimite: this.appelOffre.dateLimite,
        dateOuverture: this.appelOffre.dateOuverture,
        dateFermeture: this.appelOffre.dateFermeture,
        etat: 'VALIDE',
        projetMarcheId: this.appelOffre.projetMarche.id,
        domaineMetiersIds: this.appelOffre.domaineMetiers.map((x) => x.id),
        entreprisesChoisisIds: entity.entreprisesChoisisIds,
      };
      this.createOrUpdate({ data, payload: { queryParams: { flag: 'SET_NEW_STATUS' } } });
      this.dialogStates.isValidOpened = false;
    },
    async rejected() {
      const data = {
        id: this.appelOffre.id,
        reference: this.appelOffre.reference,
        intitule: this.appelOffre.intitule,
        objet_ao: this.appelOffre.objet_ao,
        details: this.appelOffre.details,
        motifRejet: this.motif_rejet,
        motifRenvoi: this.motif_renvoie,
        datePublication: this.appelOffre.datePublication,
        //datePublication: '2022-10-22',
        dateLimite: this.appelOffre.dateLimite,
        dateOuverture: this.appelOffre.dateOuverture,
        dateFermeture: this.appelOffre.dateFermeture,
        etat: 'REJETE',
        projetMarcheId: this.appelOffre.projetMarche.id,
        domaineMetiersIds: this.appelOffre.domaineMetiers.map((x) => x.id),
      };
      this.createOrUpdate({ data, payload: { queryParams: { flag: 'SET_NEW_STATUS' } } });
      /*await http
        .put('/appel-offres/' + this.appelOffre.id + '&flag=SET_NEW_STATUS', data, this.config)
        .then((res) => {
          this.fetchAllAO();
          this.hideDialog();
          console.log('***REJECT AO WITH SUCCESS****' + res);
        })
        .catch((e) => {
          console.log('***WRONG : REJECT AO WITH ERROR**** =>' + e);
        });*/
    },
    async revoked() {
      const data = {
        id: this.appelOffre.id,
        reference: this.appelOffre.reference,
        intitule: this.appelOffre.intitule,
        objet_ao: this.appelOffre.objet_ao,
        details: this.appelOffre.details,
        motifRejet: this.motif_rejet,
        motifRenvoi: this.motif_renvoie,
        datePublication: this.appelOffre.datePublication,
        //datePublication: '2022-10-22',
        dateLimite: this.appelOffre.dateLimite,
        dateOuverture: this.appelOffre.dateOuverture,
        dateFermeture: this.appelOffre.dateFermeture,
        etat: 'RENVOYE',
        projetMarcheId: this.appelOffre.projetMarche.id,
        domaineMetiersIds: this.appelOffre.domaineMetiers.map((x) => x.id),
      };
      this.createOrUpdate({ data, payload: { queryParams: { flag: 'SET_NEW_STATUS' } } });
      this.hideDialog();
      /*await http
        .put('/appel-offres/' + this.appelOffre.id + '/?flag=SET_NEW_STATUS', data, this.config)
        .then((res) => {
          this.fetchAllAO();
          this.hideDialog();
          this.motif_rejet = '';
          console.log('***REVOKE AO WITH SUCCESS****' + res);
        })
        .catch((e) => {
          console.log('***WRONG : REVOKE AO WITH ERROR**** =>' + e);
        });*/
    },

    valid(ao) {
      this.appelOffre = ao;
      this.$store.commit('appelsOffres/setSelectedEntityId', ao.id);
      this.fetchPrestatairesById({ id: ao.id });
      this.dialogStates.isValidOpened = true;
      this.visibleLeft = false;
    },
    reject(ao) {
      this.motif_rejet = '';
      this.appelOffre = ao;
      this.rejectDialog = true;
    },
    revoke(ao) {
      this.motif_renvoie = '';
      this.appelOffre = ao;
      this.revokeDialog = true;
      this.visibleLeft = false;
    },
    hideDialog() {
      this.dialogStates.isValidOpened = false;
      this.validDialog = false;
      this.revokeDialog = false;
      this.rejectDialog = false;
    },
  },
};
</script>

<style lang="scss">
.width--500px {
  width: 500px !important;
}
.prestataires-list,
.selected-prestataire__list {
  list-style: none;
  padding: 0;
}
.item--titled {
  font-weight: bolder;
  border-bottom: 2px solid #e7e7e7;
  margin-bottom: 4px;
}
.prestataires-item {
  border-bottom: 1px solid #e7e7e7;
  padding: 5px;
  cursor: pointer;
}
.prestataires-item:hover {
  background-color: #f3f3f3;
}
.text-danger {
  color: #f44336 !important;
}
.prestataires {
  display: flex;
}
.prestataires--selected {
  background-color: #ddf0b2;
}
.prestataires__all {
  width: 60%;
}
.prestataires__details {
  width: 40%;
  border-left: 2px solid #e7e7e7;
  padding-left: 20px;
}
.selected-prestataire__item {
  list-style: none;
  padding: 0;
}

.to--right {
  float: right;
}
.mrg {
  margin: 0 5px !important;
}

.detail-list-item {
  padding: 10px 5px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 1rem;
  display: inline-block;
  width: 100%;
}
.detail-list-item:hover {
  background-color: #eeeeee;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }

  &.status-init {
    background: #deddf5;
    color: #101b61;
  }
}
</style>
